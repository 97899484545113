import React, { useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';

import Button, { ButtonType } from 'components/Button/Button';
import { ReactComponent as CheckIcon } from 'images/green-check-rounded.svg';
import { getStudentLoanApplication } from 'selectors/getStudentLoanApplication';

import EcfFormPreview from './EcfFormPreview';

import styles from './SignatureCollector.module.scss';

interface SignatureCollectorArgs {
  onSubmit: (signatureImage: string) => Promise<void>;
}

export const SignatureCollector = ({ onSubmit }: SignatureCollectorArgs) => {
  const sigCanvasRef = useRef<SignatureCanvas>(null);

  const [enableSignatureSave, setEnableSignatureSave] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    applicationId,
    applicationData: { applyProgress },
  } = useSelector(getStudentLoanApplication);

  const onEnd = () => {
    if (countPixelsInCanvas(sigCanvasRef.current!.getTrimmedCanvas()) > 10_000) {
      setEnableSignatureSave(true);
    }
  };

  const countPixelsInCanvas = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext('2d')!;
    const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    let count = 0;
    for (let i = 0; i < imgData.data.length; i += 4) {
      // nontransparent = imgData.data[i+3]==0
      if (imgData.data[i + 3] === 0) {
        count += 1;
      }
    }
    return count;
  };

  const saveSignature = () => {
    if (sigCanvasRef.current) {
      setIsSubmitting(true);
      setEnableSignatureSave(false);
      const signatureImage = sigCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      onSubmit(signatureImage).then(() => {
        setEnableSignatureSave(true);
        setIsSubmitting(false);
      });
    }
  };

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
      setEnableSignatureSave(false);
    }
  };

  return (
    <div className={styles.container} style={{ alignContent: 'center' }}>
      <EcfFormPreview applicationId={applicationId!} />
      <div className={styles.signatureContainer}>
        {!applyProgress?.enrollmentForm && (
          <>
            <p>Please draw your signature below:</p>
            <SignatureCanvas
              ref={sigCanvasRef}
              penColor="black"
              onEnd={onEnd}
              canvasProps={{
                width: 400,
                height: 140,
              }}
            />
            <Button className={styles.button} onClick={clearSignature} type={ButtonType.Secondary}>
              Clear
            </Button>
          </>
        )}

        {applyProgress?.enrollmentForm && (
          <>
            <div className={styles.uploaded}>
              <CheckIcon className={styles.checkIcon} />
              <p className={styles.verifiedLabel}>Enrollment form uploaded</p>
            </div>
          </>
        )}

        {!applyProgress?.enrollmentForm && (
          <Button
            className={styles.button}
            onClick={saveSignature}
            disabled={!enableSignatureSave || applyProgress?.enrollmentForm}
            isLoading={isSubmitting}
          >
            Submit Authorization
          </Button>
        )}
      </div>
    </div>
  );
};

export default SignatureCollector;
